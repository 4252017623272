import { Chart, MetricInfo } from "./Metrics";
import { AdGroupAdTag, AdGroupAdTagResponse } from "./Tagging";
import { AdFormat, Provider } from "~/types/shared";

export enum AdGroupAdStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface AdInfoResponse {
  id: number;
  providerId: string;
  providerName: string;
  deleted: boolean;
  adGroupAdStatus: AdGroupAdStatus;
  cta: string | null;
  landingPageUrl: string | null;
  headline: string | null;
  description: string | null;
  postId: string | null;
  creativeId: string | null;
  createDate: string | null;
  createdAt: string;
  updatedAt: string;
  connectionId: number;
  adAccountId: number;
  adCampaignId: number;
  adGroupId: number;
  provider: Provider;
  adFormat: AdFormat | null;
  isLabeled: boolean;
  tags: Array<AdGroupAdTagResponse>;
  imageIds: Array<string>;
  videoIds: Array<string>;
}

export interface CreativeInfoResponse extends AdInfoResponse {
  images: Array<string> | null;
  videos: Array<string> | null;
}

export interface CreativeInfoWithMetricsResponse extends CreativeInfoResponse {
  primaryMetric: {
    name: string;
    curValue: number;
    compareValue: number;
    relPosition: number;
    absPosition: number;
  };
  metrics: Array<{
    name: string;
    curValue: number;
    compareValue: number;
    relPosition: number;
    absPosition: number;
  }>; // All metrics i.e. primary metric + secondary metrics
}

export interface CreativeResponse {
  info: CreativeInfoWithMetricsResponse;
  chart?: Chart;
}

export interface AssetInfo {
  providerId: string;
  images: Array<string>;
  videos: Array<string>;
}

export interface AdInfo {
  id: number;
  providerId: string;
  providerName: string;
  deleted: boolean;
  adGroupAdStatus: AdGroupAdStatus;
  cta: string | null;
  landingPageUrl: string | null;
  headline: string | null;
  description: string | null;
  postId: string | null;
  creativeId: string | null;
  createDate: string | null;
  createdAt: string;
  updatedAt: string;
  connectionId: number;
  adAccountId: number;
  adCampaignId: number;
  adGroupId: number;
  provider: Provider;
  adFormat: AdFormat | null;
  isLabeled: boolean;
  tags: Array<AdGroupAdTag>;
  imageIds: Array<string>;
  videoIds: Array<string>;
}

export interface CreativeInfo extends AdInfo, AssetInfo {
  image: string | null;
  video: string | null;
}

export interface CreativeInfoWithMetrics extends CreativeInfo {
  primaryMetric: MetricInfo;
  metrics: Array<MetricInfo>;
}

export interface Creative {
  info: CreativeInfoWithMetrics;
  chart?: Chart;
}

export type AdCampaignInfo = {
  id: number;
  providerId: string;
  providerName: string;
  campaignStatus: string;
  objective: string | null;
  campaignType: string | null;
  roasBid: number | null;
  optimizationGoal: string | null;
  dailyBudget: number | null;
  lifetimeBudget: number | null;
  campaignStartDate: string;
  campaignEndDate: string | null;
  bidStrategy: string | null;
  createdAt: string;
  updatedAt: string;
  connectionId: number;
  adAccountId: number;
  provider: Provider;
};

export type AdGroupInfo = {
  id: number;
  providerId: string;
  providerName: string;
  adGroupStatus: string;
  targetRoas: number | null;
  optimizationGoal: string | null;
  adsetSchedule: string | null;
  bidStrategy: string | null;
  bidAmount: number | null;
  dailyMinSpendTarget: number | null;
  dailySpendCap: number | null;
  adGroupStartDate: string | null;
  adGroupEndDate: string | null;
  dailyBudget: number | null;
  lifetimeBudget: number | null;
  createdAt: string;
  updatedAt: string;
  adCampaignId: number;
  adAccountId: number;
  connectionId: number;
  provider: Provider;
};
